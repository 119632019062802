.shepherd-modal-overlay-container {
    height: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms;
    width: 100vw;
    z-index: 9997;

    &.shepherd-modal-is-visible {
        height: 100vh;
        opacity: 0.7;
        transform: translateZ(0);
        transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;

        path {
            pointer-events: all;
        }
    }
}

.shepherd-element {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    max-width: 480px;
    opacity: 0;
    outline: none;
    transition: opacity 0.3s, visibility 0.3s;
    visibility: hidden;
    width: 100%;
    z-index: 9999;
}

.shepherd-content {
    padding: 25px;

    a {
        &,
        &:active,
        &:visited {
            color: #5e43fd;
        }

        &:hover {
            color: #ff6400;
        }
    }
}

.shepherd-enabled.shepherd-element {
    opacity: 1;
    visibility: visible;
}

.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
}

.shepherd-element,
.shepherd-element *,
.shepherd-element :after,
.shepherd-element :before {
    box-sizing: border-box;
}

.shepherd-arrow,
.shepherd-arrow:before {
    height: 16px;
    position: absolute;
    width: 16px;
    z-index: -1;
}

.shepherd-arrow:before {
    background: #fff;
    content: '';
    transform: rotate(45deg);
}

.shepherd-element[data-popper-placement^='top'] > .shepherd-arrow {
    bottom: -8px;
}

.shepherd-element[data-popper-placement^='bottom'] > .shepherd-arrow {
    top: -8px;
}

.shepherd-element[data-popper-placement^='left'] > .shepherd-arrow {
    right: -8px;
}

.shepherd-element[data-popper-placement^='right'] > .shepherd-arrow {
    left: -8px;
}

.shepherd-element.shepherd-centered > .shepherd-arrow {
    opacity: 0;
}

.shepherd-element.shepherd-has-title[data-popper-placement^='bottom']
    > .shepherd-arrow:before {
    background-color: #e6e6e6;
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
    pointer-events: none;
}

.shepherd-cancel-icon {
    position: absolute;
    top: 0;
    right: 10px;
    background: transparent;
    border: none;
    color: hsla(0, 0%, 50%, 0.75);
    cursor: pointer;
    font-size: 2em;
    font-weight: 400;
    margin: 0;
    padding: 0;
    transition: color 0.5s ease;
}

.shepherd-cancel-icon:hover {
    color: rgba(0, 0, 0, 0.75);
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
    color: hsla(0, 0%, 50%, 0.75);
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover {
    color: rgba(0, 0, 0, 0.75);
}

.shepherd-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;

    button {
        height: 34px;
        min-height: 34px;
        font-size: 17px;
        line-height: 22px;
        padding: 0 10px 1px;
        outline: none;
        border-radius: 12px;
        white-space: nowrap;
        transition: all 0.2s ease-in-out;
        font-family: var(--font-semibold);

        &.primary {
            color: white;
            background-color: #5e43fd;

            &:visited,
            &:active {
                background: #5e43fd;
                color: white;
            }
        }

        &.tertiary {
            background: transparent;
            color: #1f1a22;
            border: 3px solid rgba(76, 71, 108, 0.08);

            @media (pointer: fine) {
                &:hover {
                    border-color: #5e43fd;
                    background: transparent;
                    color: #1f1a22;
                }
            }

            &.active,
            &:active {
                border-color: #5e43fd;
                color: #1f1a22;
                background: transparent;
            }

            &:visited {
                color: #1f1a22;
            }
        }
    }

    button.primary:only-child {
        margin-left: auto;
    }
}
