@font-face {
    font-family: 'gellix-bold';
    src: url('/fonts/Gellix-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'gellix-medium';
    src: url('/fonts/Gellix-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'gellix-semi';
    src: url('/fonts/Gellix-SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
}

:root {
    --font-semibold: 'gellix-semi';
    --font-regular: 'gellix-medium';
    --font-bold: 'gellix-bold';

    /* Body sizes */
    --font-size-body-medium: 24px;
    --font-size-body-small: 20px;
    --font-size-body-smaller: 17px;
    --font-size-body-nano: 14px;

    /* Heading sizes */
    --font-size-heading-xxl: 110px;
    --line-height-heading-xxl: 120px;

    --font-size-heading-xl: 72px;
    --line-height-heading-xl: 80px;

    --font-size-heading-large: 42px;
    --line-height-heading-large: 46px;

    --font-size-heading-medium: 28px;
    --line-height-heading-medium: 36px;

    --font-size-heading-small: 24px;
    --line-height-heading-small: 28px;

    /* Distances */
    --distance-150: 150px;
    --distance-120: 120px;
    --distance-100: 100px;
    --distance-80: 80px;
    --distance-60: 60px;
    --distance-48: 48px;
    --distance-40: 40px;
    --distance-32: 32px;
    --distance-24: 24px;
    --distance-16: 16px;
    --distance-12: 12px;
    --distance-8: 8px;
    --distance-4: 4px;
}

/* Tablet variables override */
@media (min-width: 768px) and (max-width: 1024px) {
    :root {
        --font-size-body-medium: 20px;
        --font-size-body-small: 17px;
        --font-size-body-smaller: 17px;
        --font-size-body-nano: 12px;

        --font-size-heading-xxl: 72px;
        --line-height-heading-xxl: 80px;

        --font-size-heading-xl: 60px;
        --line-height-heading-xl: 62px;

        --font-size-heading-large: 36px;
        --line-height-heading-large: 42px;

        --font-size-heading-medium: 24px;
        --line-height-heading-medium: 30px;

        --font-size-heading-small: 20px;
        --line-height-heading-small: 28px;

        --distance-150: 100px;
        --distance-120: 80px;
        --distance-100: 80px;
        --distance-80: 60px;
        --distance-60: 48px;
        --distance-48: 36px;
        --distance-40: 30px;
        --distance-32: 24px;
        --distance-24: 16px;
        --distance-16: 12px;
        --distance-12: 8px;
        --distance-8: 6px;
        --distance-4: 4px;
    }
}

.fs-nano {
    font-size: var(--font-size-body-nano);
    line-height: 1.5;
}

/* Mobile variables override */
@media (max-width: 767px) {
    :root {
        --font-size-body-medium: 17px;
        --font-size-body-small: 16px;
        --font-size-body-smaller: 14px;
        --font-size-body-nano: 12px;

        --font-size-heading-xxl: 42px;
        --line-height-heading-xxl: 48px;

        --font-size-heading-xl: 42px;
        --line-height-heading-xl: 48px;

        --font-size-heading-large: 32px;
        --line-height-heading-large: 40px;

        --font-size-heading-medium: 20px;
        --line-height-heading-medium: 26px;

        --font-size-heading-small: 17px;
        --line-height-heading-small: 22px;

        --distance-150: 80px;
        --distance-120: 60px;
        --distance-100: 60px;
        --distance-80: 48px;
        --distance-60: 32px;
        --distance-48: 24px;
        --distance-40: 20px;
        --distance-32: 16px;
        --distance-24: 12px;
        --distance-16: 8px;
        --distance-12: 4px;
        --distance-8: 4px;
        --distance-4: 4px;
    }
}
